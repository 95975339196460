import * as Core from '../core/core';
import anime from 'animejs/lib/anime.es.js';

class Drawer {

	constructor(){
		this.target = null;

		this.init();
		this.onClickEvents();
	}

	onClickEvents(){
		Core.onClickEvent(`${this.target} [data-drawer="close"]`, this, 'close');
	}

	init(){}

	open(){
		anime({
			targets: this.target,
			translateY: ['-100%', '0%'],
			opacity: [0, 1],
			easing: 'cubicBezier(.2, .8, .4, 1)',
			duration: 400,
			begin: (anim) => {
				$('body').css('overflow', 'hidden');
				$(this.target).css('display', 'block');
			},
			complete: (anim) => {
				this.onOpen();
			}
		});
	}

	close(){
		anime({
			targets: this.target,
			opacity: [1, 0],
			easing: 'cubicBezier(.2, .8, .4, 1)',
			duration: 400,
			begin: (anim) => {},
			complete: (anim) => {
				$('body').css('overflow', '');
				$(this.target).css('display', 'none');
			}
		});
	}

	onOpen(){}
}

export default Drawer;