import * as Core from '../core/core';
import Drawer from "./drawer";

class Language extends Drawer {

	init(){
		this.target = '#language';
	}

	onClickEvents(){
		super.onClickEvents();

		Core.onClickEvent('[data-language="open"]', this, 'open');
	}
}

export default Language;