//import "imports?$=jquery!jquery.first-event";

/**
 * Check if variable is EMPTY
 *
 * @param mixed_var
 * @returns {boolean}
 */
export function empty(mixed_var){
	var undef, key, i, len;
	var emptyValues = [undef, null, false, 0, "", "0"];

	for(i = 0, len = emptyValues.length; i < len; i++){
		if(mixed_var === emptyValues[i]){
			return true;
		}
	}

	if(typeof mixed_var === "object"){
		for(key in mixed_var){
			return false;
		}

		return true;
	}

	return false;
}

export function exists(selector){
	if(typeof selector !== "object"){
		selector = $(selector);
	}

	return (selector.length > 0);
}

export function onClickEvents(object, methods){
	for(var element in methods){
		onClickEvent(element, object, methods[element]);
	}
}

export function onClickEvent(element, object, method){
	$('body').on('click', element, function(event){
		if(typeof object[method] !== 'function'){
			console.error("Try to call undefined method \""+ method +"\"");
		} else{
			object[method]($(this), event);
		}
	});
}

export function onClickFirstEvents(object, methods){
	for(var element in methods){
		let method = methods[element];

		$('body').firstOn('click', element, function(event){
			if(typeof object[method] !== 'function'){
				console.error("Try to call undefined method \""+ method +"\"");
			} else{
				object[method]($(this), event);
			}
		});
	}
}

export function onChangeEvents(object, methods){
	for(var element in methods){
		let method = methods[element];

		$('body').on('change', element, function(event){
			if(typeof object[method] !== 'function'){
				console.error("Try to call undefined method \""+ method +"\"");
			} else{
				object[method]($(this), event);
			}
		});
	}
}

export function onSubmitEvents(object, methods){
	for(var element in methods){
		let method = methods[element];

		$('body').on('submit', element, function(event){
			if(typeof object[method] !== 'function'){
				console.error("Try to call undefined method \""+ method +"\"");
			} else{
				object[method]($(this), event);
			}
		});
	}
}

export function onFocusEvents(object, methods){
	for(var element in methods){
		let method = methods[element];

		$('body').on('focus', element, function(event){
			if(typeof object[method] !== 'function'){
				console.error("Try to call undefined method \""+ method +"\"");
			} else{
				object[method]($(this), event);
			}
		});
	}
}

export function onFocusOutEvents(object, methods){
	for(var element in methods){
		let method = methods[element];

		$('body').on('focusout', element, function(event){
			if(typeof object[method] !== 'function'){
				console.error("Try to call undefined method \""+ method +"\"");
			} else{
				object[method]($(this), event);
			}
		});
	}
}

export function onScrollEvents(object, methods){
	for(var element in methods){
		let method = methods[element];

		$(window).scroll(function(event){
			if(typeof object[method] !== 'function'){
				console.error("Try to call undefined method \""+ method +"\"");
			} else{
				object[method](event);
			}
		});
	}
}

export function onResizeEvents(object, methods){
	for(var element in methods){
		let method = methods[element];

		$(window).resize(function(event){
			if(typeof object[method] !== 'function'){
				console.error("Try to call undefined method \""+ method +"\"");
			} else{
				object[method](event);
			}
		});
	}
}

export function onInputEvents(object, methods){
	for(var element in methods){
		let method = methods[element];

		$('body').on('input', element, function(event){
			if(typeof object[method] !== 'function'){
				console.error("Try to call undefined method \""+ method +"\"");
			} else{
				object[method]($(this), event);
			}
		});
	}
}

/**
 * Uppercase first character of string
 *
 * @param {string} string
 * @returns {string}
 */
export function ucfirst(string){
	return string.charAt(0).toUpperCase() + string.substr(1);
}

export function makeArray(object){
	if(Array.isArray(object)){
		return object;
	}

	return [object];
}

/**
 * Unique ID for Object prototype
 */
(function(){
	var id = 0;

	Object.defineProperty(Object.prototype, "uniqueID", {
		value: function(){
			if(typeof this.__uniqueid == "undefined"){
				this.__uniqueid = ++id;
			}

			return this.__uniqueid;
		},
		enumerable: false
	});
})();

/**
 * Validate e-mail
 *
 * @param {string} email
 * @returns {RegExpExecArray | null}
 * @deprecated
 */
export function validateEmail(email){
	// https://emailregex.com/
	var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

	return regexp.exec(email);
}

/**
 * Format price
 *
 * @param {float} number
 * @param {int} dec_places
 * @param {string} thou_separator
 * @param {string} dec_separator
 * @returns {string}
 */
export function priceFormat(number, dec_places, thou_separator, dec_separator){
	var dec_places = isNaN(dec_places = Math.abs(dec_places)) ? 2 : dec_places,
		dec_separator = dec_separator == undefined ? "," : dec_separator,
		thou_separator = thou_separator == undefined ? " " : thou_separator,

		i = parseInt(number = Math.abs(+number || 0).toFixed(dec_places)) + "",
		j = (j = i.length) > 3 ? j % 3 : 0;

	return (j ? i.substr(0, j) + thou_separator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thou_separator) + (dec_places ? dec_separator + Math.abs(number - i).toFixed(dec_places).slice(2) : "");
};

/**
 * Scroll to element position
 *
 * @param {object} element
 * @param {int} offset
 * @param {int} speed
 */
export function scrollTo(element, offset, speed){
	var top = element.offset().top;

	if(offset !== undefined){
		top = top + offset;
	}

	if(speed !== undefined){
		speed = 600;
	}

	$('html, body').animate({
		'scrollTop': top
	}, speed);
}

/**
 * Check if string contains substring
 *
 * @param {string} string
 * @param {string|array} substring
 * @returns {boolean}
 */
export function hasSubstring(string, substring){
	substring = makeArray(substring);

	for(var i = 0; i < substring.length; i++){
		if(string.indexOf(substring[i]) !== -1){
			return true;
		}
	}

	return false;
}

/**
 * Generate Regex to match diacritic
 *
 * @param {string} input
 * @returns {string}
 */
export function diacriticRegex(input){
	var mappings = {
		'a': String.fromCharCode(65, 97, 192, 224, 193, 225, 194, 226, 195, 227, 196, 228, 229, 258, 259),
		'c': String.fromCharCode(67, 99, 199, 231),
		'd': String.fromCharCode(100, 270, 271),
		'e': String.fromCharCode(69, 101, 200, 232, 201, 233, 202, 234, 203, 235),
		'i': String.fromCharCode(73, 105, 204, 236, 205, 237, 206, 238, 207, 239),
		'n': String.fromCharCode(78, 110, 209, 241),
		'o': String.fromCharCode(79, 111, 210, 242, 211, 243, 212, 244, 213, 245, 214, 246),
		'r': String.fromCharCode(114, 345, 344, 341, 340),
		's': String.fromCharCode(115, 353, 352, 347, 346),
		't': String.fromCharCode(116, 357, 356),
		'u': String.fromCharCode(85, 117, 217, 249, 218, 250, 219, 251, 220, 252),
		'y': String.fromCharCode(89, 121, 221, 253, 159, 255),
		'z': String.fromCharCode(122, 382, 381, 378, 377)
	};

	return input.split('').map(function(letter){
		for(var mapping in mappings){
			if (mapping && mapping !== mappings[mapping] && (mapping === letter || mappings[mapping].indexOf(letter) !== -1)){
				letter = Array.isArray(mappings[mapping]) ? mappings[mapping].join('') : "[" + mappings[mapping] + "]";
				break;
			}
		}

		return letter;
	}).join('');
}

/**
 * Case insensitive in array
 *
 * @param {string} elem
 * @param {array} arr
 * @param {int} i the index of the array at which to begin the search. The default is 0, which will search the whole array.
 * @returns {boolean}
 */
export function inArray(elem, arr, i){
	var len = arr.length;

	i = i ? (i < 0 ? Math.max(0, len + i) : i) : 0;
	elem = elem.toLowerCase();

	for(; i < len; i++){
		if(i in arr && arr[i].toLowerCase() == elem){
			return true;
		}
	}

	return false;
}

/**
 * Key exists in array
 *
 * @param {string|int} key
 * @param {array} arr
 * @returns {boolean}
 */
export function arrayKeyExists(key, arr){
	return !!(key in arr);
}

/**
 * Random value
 *
 * @param {int} min
 * @param {int} max
 * @returns {int}
 */
export function random(min, max) {
	return Math.floor(Math.random() * (max - min) + min);
}

export function arrayRemove(arr, value){
	return arr.filter(function(ele){
		return ele != value;
	});
}